import getGlobal from "rtUtils/getGlobal";
import merge from "rtUtils/merge";
import { VIEWERS } from "./consts";
import DEFAULTS from "./defaults";
import initializeTag from "./initializeTag";
import getTagApi from "./tagApi";
import createAnalyticsEventsManager from "./analyticsEventsManager";

const root = getGlobal();

const getTagConfig = (userConfig) => {
	let config = merge({}, DEFAULTS, userConfig);

	if (userConfig.account) {
		console.warn("Cloudinary 3D Tag - 'account' param is deprecated, please use 'cloudName' instead");
	}

	return {
		update: (newConfig) => {
			// currently doesn't support adding viewer types after first init
			merge(config, newConfig);
			return config;
		},
		getConfig: () => config,
	};
};

const getInitializer = () => {
	let isInitialized = false;

	return (config) => {
		if (isInitialized && config.forceReInit !== true) {
			//protect from multiple init (can easily happen when inside React StrictMode dev env)
			console.warn("Cloudinary 3D Tag - already initialized");
			// return;
		}

		const updateableConfig = getTagConfig(config);
		const eventsManager = createAnalyticsEventsManager(updateableConfig);
		const initPromise = initializeTag(updateableConfig, eventsManager);

		isInitialized = true;

		initPromise.then(({ render }) => {
			if (updateableConfig.getConfig().autoRender) {
				render();
			}
		});

		return getTagApi(updateableConfig, eventsManager, initPromise);
	};
};

root.initDimensions = getInitializer();

root.initDimensions.VIEWERS = VIEWERS;
