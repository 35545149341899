import { warn } from "rtUtils/logger";
import { VIEWERS } from "./consts";
import capitalizeFirstLetter from "./utils/capitalizeFirstLetter";

const getTagApi = (updateableConfig, eventsManager, initPromise) => {
	const addViewerOfType = async (type, container, props) => {
		const { viewers } = await initPromise;

		if (viewers[type]) {
			viewers[type].render([{ container, containerProps: { type, ...props }}]);
		} else {
			warn(`Cloudinary 3D API - cannot render viewer of ${type} without it being configured`);
		}
	};

	const updateViewerOfType = async (type, container, props) => {
		const { viewers } = await initPromise;

		if (viewers[type]) {
			viewers[type].update([{ container, containerProps: { type, ...props }}]);
		} else {
			warn(`Cloudinary 3D API - cannot update viewer of ${type} without it being configured`);
		}
	};

	const removeViewerOfType = async (type, container) => {
		const { viewers } = await initPromise;

		if (viewers[type]) {
			viewers[type].remove([container]);
		} else {
			warn(`Cloudinary 3D API - cannot remove viewer of ${type} without it being configured`);
		}
	}

	const getConfigForType = async (type, container) => {
		const { viewers } = await initPromise;
		let conf;

		if (viewers[type]) {
			conf = viewers[type].getConfig(container);
		} else {
			warn(`Cloudinary 3D API - cannot get viewer config for ${type} without it being configured`);
		}

		return conf;
	};

	const addTypeMethods = (api, type) => {
		const methodType = capitalizeFirstLetter(type.toLowerCase());

		api[`add${methodType}`] = (...args) => {
			addViewerOfType(type, ...args);
			return api;
		};

		api[`update${methodType}`] = (...args) => {
			updateViewerOfType(type, ...args);
			return api;
		};

		api[`remove${methodType}`] = (...args) => {
			removeViewerOfType(type, ...args);
			return api;
		};

		api[`get${methodType}Config`] = (...args) =>{
			return getConfigForType(type, ...args);
		};
	};

	const api = {
		update: (updated) => {
			updateableConfig.update(updated);
			return api;
		},

		render: async () =>  {
			const { render } = await initPromise;
			render();
			return api;
		},

		on: (listener) => {
			return eventsManager.addListener(listener);
		},
	};

	Object.values(VIEWERS)
		.forEach((type) => addTypeMethods(api, type));

	return api;
};

export default getTagApi;

