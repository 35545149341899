import { debug, warn } from "rtUtils/logger";
import findViewerContainers from "./findViewerContainers";

const renderWithViewers = (viewers, { getConfig }) => {
	debug("renderWithViewers - ", { viewers });

	if (Object.keys(viewers).length) {
		const containersData = findViewerContainers(getConfig());
		debug("renderWithViewers - CONTAINERS DATA ", containersData);

		Object.entries(containersData.counters)
			.forEach(([type, count]) => {
				if (count > 0) {
					debug(`renderWithViewers - found ${count} containers for ${type}`, viewers[type]);

					if (viewers[type]) {
						viewers[type].render(containersData.containers[type]);
					} else {
						warn(`Cloudinary 3D Tag - found ${type} containers but matching viewer was not configured`);
					}
				}
			});

	} else {
		warn("Cloudinary 3D Tag - initialized with no valid viewers. Check your config");
	}
};

export default renderWithViewers;
