import { ANALYTICS_END_POINT } from "./consts";
import { getCloudFromConfig } from "./utils";

const ANALYTICS_SEND_INTERVAL = 1000;
const TAG_VERSION = process.env.TAG_VERSION;
const IS_STAGING = process.env.TAG_ENV.toLowerCase() === "staging";

const sendAnalyticsData = (events, config) => {
	const metadata = {
		time: Date.now(),
		url: location.href,
		shortUrl: `${location.protocol}//${location.host}${location.pathname}`,
		domain: location.hostname,
		account: getCloudFromConfig(config, true),
		tagVersion: TAG_VERSION,
		ua: navigator.userAgent,
	};

	fetch(ANALYTICS_END_POINT, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-D8S-EVENTS": process.env.EVENTS_HEADER,
			"X-D8S-ROUTER": process.env.ROUTER_HEADER,
		},
		body: JSON.stringify({
			reqType: "events",
			request: {
				staging: IS_STAGING,
				d8sEvents: events.map((event) => ({
					...event,
					metadata,
					eventType: event.args[0],
					success: !event.args[1].error,
				})),
			}
		}),
	});
};

const createAnalyticsEventsManager = (updateableConfig) => {
	const listeners = new Set();
	let senderIntervalHandler = null;
	let pendingAnalyticsEvents = [];

	const addListener = (fn) => {
		if (!listeners.has(fn)) {
			listeners.add(fn);
		}

		//return unregister fn
		return () => {
			if (listeners.has(fn)) {
				listeners.delete(fn);
			}
		};
	};

	const trigger = (name, ...args) => {
		listeners.forEach((listener) => {
			listener(name, ...args);
		});
	};

	const triggerWithAnalytics = (...args) => {
		const config = updateableConfig.getConfig();
		//if reporting is enabled
		if (config.report) {
			if (!senderIntervalHandler) {
				//group events sending to reduce network requests
				senderIntervalHandler = setTimeout(() => {
					sendAnalyticsData(pendingAnalyticsEvents, config);
					pendingAnalyticsEvents = [];
					senderIntervalHandler = null;
				}, ANALYTICS_SEND_INTERVAL);
			}

			pendingAnalyticsEvents.push({ args });
		}

		trigger(...args);
	};

	const clear = () => listeners.clear();

	return {
		addListener,
		trigger,
		triggerWithAnalytics,
		clear,
	};
};

export default createAnalyticsEventsManager;
