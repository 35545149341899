const getCloudFromConfig = (config, addPrefixToAccount = false) => {
	if (config.cloudName) {
		return config.cloudName;
	} else {
		if (addPrefixToAccount && !config.account.startsWith("d8s-")) {
			return `d8s-${config.account}`;
		} else {
			return config.account;
		}
	}
};

export default getCloudFromConfig;
