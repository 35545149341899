import { CLD_BASE_URL, API_URL } from "./consts";

const DEFAULTS = Object.freeze({
	cloudName: null,

	//d8s account name (deprecated! use cloudName instead)
	account: null,

	defaults: {
		//product sku - used as default if not specified on container
		product: null,
		//preset publish name - used as default if not specified on container
		preset: null
	},

	rootSelector: "",
	containerSelector: "*[data-d8s-id]",
	viewers: [],

	report: true,

	autoRender: true,

	imageViewer: {
		//tagParams
		params: {},
	},

	videoViewer: {
		//tagParams
		params: {},
	},

	threeDViewer: {
		//TODO: change to true when optimizations are approved
		useOptimized: false,
	},

	baseUrl: CLD_BASE_URL,
	apiUrl: API_URL,

	forceReInit: false,
});

export default DEFAULTS;
